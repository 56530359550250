@import '@mt-ng2/styles/styles.less';
@import "styles/_fonts";
@import (reference) 'styles/_global-variables';
mt-multiselect span button {
    white-space: normal!important;
}

.inline, .inline > * {
    display: inline-block;
}

.text-centered {
    text-align: center;
}

.text-right {
    text-align: right;
}

.centered {
    margin: 0 auto;
}

.no-border {
    border: none !important;
}

.pad-left-1 {
    padding-left: 1em;
}

.pad-right-1 {
    padding-right: 1em !important;
}

@media (max-width: 768px) {
    .btn.btn-fab-md {
        z-index: 2 !important;
    }

    .btn.btn-fab-md:hover {
        background-color: #3264ac !important;
    }

    span.ng2-imgcrop canvas {
        width: 95% !important;
        height: 100% !important;
    }
}

.flex-spread-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    > :first-child {
        flex-grow: 1;
    }
    > :nth-child(2) {
        flex-grow: 1;
        text-align: right;
        max-width: 50%;
    }
}

.pad-container > * {
    margin: .25em;
}

.pad-top-half {
    padding-top: 0.5em;
}


@media (max-width: 30rem) {
    .table-responsive.card-switch td
    {
        width: auto !important;
    }
}

@media screen and (max-width: 767px) {
    .table-responsive>.table>tbody>tr>td, 
    .table-responsive>.table>tbody>tr>th, 
    .table-responsive>.table>tfoot>tr>td, 
    .table-responsive>.table>tfoot>tr>th, 
    .table-responsive>.table>thead>tr>td, 
    .table-responsive>.table>thead>tr>th {
        white-space: normal !important;
        min-height: 37px !important;
    }
}
