// Icon for Users
.teenyicons--users-outline {
  display: inline-block;
  width: 1em;
  height: 1em;
  --svg: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 15 15'%3E%3Cpath fill='%23000' d='M10.5 14.49v.5h.5v-.5zm-10 0H0v.5h.5zm14 .01v.5h.5v-.5zM8 3.498a2.5 2.5 0 0 1-2.5 2.498v1C7.433 6.996 9 5.43 9 3.498zM5.5 5.996A2.5 2.5 0 0 1 3 3.498H2a3.5 3.5 0 0 0 3.5 3.498zM3 3.498A2.5 2.5 0 0 1 5.5 1V0A3.5 3.5 0 0 0 2 3.498zM5.5 1A2.5 2.5 0 0 1 8 3.498h1A3.5 3.5 0 0 0 5.5 0zm5 12.99H.5v1h10zm-9.5.5v-1.996H0v1.997zm2.5-4.496h4v-1h-4zm6.5 2.5v1.997h1v-1.997zm-2.5-2.5a2.5 2.5 0 0 1 2.5 2.5h1a3.5 3.5 0 0 0-3.5-3.5zm-6.5 2.5a2.5 2.5 0 0 1 2.5-2.5v-1a3.5 3.5 0 0 0-3.5 3.5zM14 13v1.5h1V13zm.5 1H12v1h2.5zM12 11a2 2 0 0 1 2 2h1a3 3 0 0 0-3-3zm-.5-3A1.5 1.5 0 0 1 10 6.5H9A2.5 2.5 0 0 0 11.5 9zM13 6.5A1.5 1.5 0 0 1 11.5 8v1A2.5 2.5 0 0 0 14 6.5zM11.5 5A1.5 1.5 0 0 1 13 6.5h1A2.5 2.5 0 0 0 11.5 4zm0-1A2.5 2.5 0 0 0 9 6.5h1A1.5 1.5 0 0 1 11.5 5z'/%3E%3C/svg%3E");
  background-color: currentColor;
  -webkit-mask-image: var(--svg);
  mask-image: var(--svg);
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-size: 100% 100%;
  mask-size: 100% 100%;
}

// Icon for User Roles
.tdesign--user-setting {
  display: inline-block;
  width: 1em;
  height: 1em;
  --svg: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%23000' d='M11.5 4a3.5 3.5 0 1 0 0 7a3.5 3.5 0 0 0 0-7M6 7.5a5.5 5.5 0 1 1 11 0a5.5 5.5 0 0 1-11 0M8 16a4 4 0 0 0-4 4h8.05v2H2v-2a6 6 0 0 1 6-6h4v2zm11.5-3.25v1.376c.715.184 1.352.56 1.854 1.072l1.193-.689l1 1.732l-1.192.688a4 4 0 0 1 0 2.142l1.192.688l-1 1.732l-1.193-.689a4 4 0 0 1-1.854 1.072v1.376h-2v-1.376a4 4 0 0 1-1.854-1.072l-1.193.689l-1-1.732l1.192-.688a4 4 0 0 1 0-2.142l-1.192-.688l1-1.732l1.193.688a4 4 0 0 1 1.854-1.071V12.75zm-2.751 4.283a2 2 0 0 0-.25.967c0 .35.091.68.25.967l.036.063a2 2 0 0 0 3.43 0l.036-.063c.159-.287.249-.616.249-.967c0-.35-.09-.68-.249-.967l-.036-.063a2 2 0 0 0-3.43 0z'/%3E%3C/svg%3E");
  background-color: currentColor;
  -webkit-mask-image: var(--svg);
  mask-image: var(--svg);
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-size: 100% 100%;
  mask-size: 100% 100%;
}

// Icon for Programs
.icon-park-twotone--robot-two {
  display: inline-block;
  width: 1em;
  height: 1em;
  --svg: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 48 48'%3E%3Cdefs%3E%3Cmask id='ipTRobotTwo0'%3E%3Cg fill='none' stroke='%23fff' stroke-width='4'%3E%3Cpath fill='%23555' stroke-linecap='round' stroke-linejoin='round' d='M5 35a2 2 0 0 1 2-2h34a2 2 0 0 1 2 2v7H5z'/%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M42 18h-8l-6-6l6-6h8'/%3E%3Ccircle cx='8' cy='12' r='4' fill='%23555'/%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M12 12h16m-18 4l8 17'/%3E%3C/g%3E%3C/mask%3E%3C/defs%3E%3Cpath fill='%23000' d='M0 0h48v48H0z' mask='url(%23ipTRobotTwo0)'/%3E%3C/svg%3E");
  background-color: currentColor;
  -webkit-mask-image: var(--svg);
  mask-image: var(--svg);
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-size: 100% 100%;
  mask-size: 100% 100%;
}
